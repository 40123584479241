function api_host() {
	if (process.env.NODE_ENV === 'production') {
		return 'https://main-server-1.censolut.com';
	} else {
		return 'http://localhost:8000';
	}
}

function cdn_host() {
	if (process.env.NODE_ENV === 'production') {
		return 'https://main-server-1.censolut.com';
	} else {
		return 'http://localhost:8000';
	}
}

function gg_client_id() {
	if (process.env.NODE_ENV === 'production') {
		return '230100438513-ueeu0ns40fjblodgh4tpn1p31b87nape.apps.googleusercontent.com';
	} else {
		return '230100438513-6dgma9i07f03fmo737oeuk07v49dt9qr.apps.googleusercontent.com';
	}
}

function externalPublicMediaHost() {
	if (process.env.NODE_ENV === 'production') {
		return 'https://mantic746.imgix.net';
	} else {
		return '';
	}
}

function externalCdnHost() {
	if (process.env.NODE_ENV === 'production') {
		return 'https://censor6472.imgix.net';
	} else {
		return 'http://localhost:8000';
	}
}

export const apiUrl = api_host();
export const fileUploadApi = cdn_host();
export const cdnUrl = cdn_host();
export const SocketIO_Endpoint = 'http://localhost:8080';
export const externalPublicMediaUrl = externalPublicMediaHost();
export const externalCdnUrl = externalCdnHost();
export const BillbeeShopSourceId = 'MANTIC';
export const paypalClientID = 'AUm44i6c_LKfS_shVcYc2r6_SCzvwVkcGNlgo9qpOLGH4DnT55c0B6OQRRKi9f09Py8lt8uEbZPWe3cD';
export const globalSettings = {
	google_client_id: gg_client_id(),
};
