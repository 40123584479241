import { BodySingleBlock } from '../layouts/BodySingleBlock';
import { Box, BoxProps } from '@mui/material';
import { externalPublicMediaUrl } from '../../environments/environments';
import { ShoeCollectionOne } from '../shared/ProductList';
import { Fragment } from 'react';
import { PrimaryBlackButton, PrimaryBlackTextButton } from '../UI_Components/UI_Buttons';
import Tilt from 'react-parallax-tilt';
import './_landingHomepage.scss';
import gsap from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import { useDispatch, useSelector } from 'react-redux';
import { productCartActions } from '../../store/reducers/productCartInfo.reducer';
import { AppState } from '../../store/core.store';
gsap.registerPlugin(MotionPathPlugin);

const HomepageSectionTitle = (props: BoxProps) => {
	return (
		<>
			<Box className='uppercase font-black text-2xl md:text-6xl text-center lg:text-start leading-relaxed' {...props} />
		</>
	);
};

const SpecialDiscountPanel = () => {
	return (
		<Box className='bg-cyan-200/40 p-8 flex flex-col gap-8 md:text-white text-center items-center'>
			<span data-aos='fade-up' className='uppercase'>
				Get the special discount
			</span>
			<p data-aos='fade-up' className='uppercase text-6xl font-black'>
				50%
				<br />
				off
			</p>
			<Box data-aos='fade-up' className='flex gap-4'>
				<Box className='py-2 px-4 bg-black font-bold'>Jordan X A647</Box>
				<Box className='rounded-2xl bg-gradient-to-br from-cyan-300 to-violet-300 text-white flex items-center justify-center w-12 aspect-1'>
					<i className='fa-regular fa-eye' />
				</Box>
			</Box>
		</Box>
	);
};

export const LandingHomePage = () => {
	const dispatcher = useDispatch();
	function animateProductImg(boxCls: string, imgCls: string) {
		const prodElBox = document.querySelector(boxCls);
		const prodEl = document.querySelector(imgCls);
		const cartEl = document.getElementById('headerCartIconBox');
		if (!prodEl || !cartEl || !prodElBox) return;
		const pos = MotionPathPlugin.getRelativePosition(prodEl, cartEl, [0.5, 0.5], [0.5, 0.5]);
		gsap
			.timeline()
			.to(prodElBox, {
				zIndex: 100,
				duration: 0,
			})
			.to(prodEl, {
				x: `+=${pos.x}`,
				y: `+=${pos.y}`,
				scale: 0.2,
				opacity: 0,
				autoAlpha: 0,
				duration: 1,
			})
			.to(prodEl, {
				clearProps: 'all',
				duration: 0,
			})
			.to(prodElBox, {
				zIndex: 0,
				duration: 0,
			});
	}

	const userInfo = useSelector((e: AppState) => e.user_info.data);
	return (
		<>
			<BodySingleBlock className='mb-[100px]'>
				<Box
					className='px-4 py-[50px] relative flex flex-col gap-8 w-full md:min-h-[80vh] rounded-[30px] overflow-hidden bg-cover bg-center'
					style={{
						backgroundImage: `url('${externalPublicMediaUrl}/img/1389bhjk42_result.webp')`,
					}}
				>
					<Box className='w-full md:w-[40%] md:absolute md:right-[10%] md:top-1/2 md:-translate-y-1/2'>
						<Tilt>
							<img
								src={`${externalPublicMediaUrl}/img/sneaker-4bjih2_result.webp`}
								className='w-full h-auto rounded-2xl animate-[shake-vertical_10s_linear_infinite_both]'
							/>
						</Tilt>
					</Box>
					<Box className='hidden md:block md:w-[40%] md:absolute md:top-1/2 md:-translate-y-1/2 md:left-[10%]'>
						<SpecialDiscountPanel />
					</Box>
					<Box className='text-center md:text-end md:absolute md:right-[10%] md:bottom-[5%] flex flex-col gap-2 text-white'>
						<span className='uppercase text-4xl font-bold' data-aos='fade-up' data-aos-delay={200}>
							Mantic Shop
						</span>
						<span className='text-2xl capitalize' data-aos='fade-up' data-aos-delay={500}>
							Futuristic Shoe House
						</span>
						<span className='text-2xl capitalize' data-aos='fade-up' data-aos-delay={800}>
							2023
						</span>
					</Box>
				</Box>
			</BodySingleBlock>
			<BodySingleBlock className='mb-[100px]'>
				<Box className='flex flex-col lg:flex-row gap-8 lg:items-start'>
					<Box className='md:flex-grow flex justify-center lg:justify-start'>
						<Box className='flex flex-col gap-16'>
							<HomepageSectionTitle data-aos='fade-up'>Featured</HomepageSectionTitle>
							<Box className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
								{ShoeCollectionOne.map((shoe, i) => (
									<Fragment key={i}>
										{i <= 3 ? (
											<>
												<Box
													data-aos='fade-up'
													data-aos-delay={i * 200}
													className={`p-4 flex items-center gap-4 rounded-[15px] border-2 border-slate-500/20 hover:-translate-y-2 duration-500 max-w-[400px] homeProductFEDBox${i}`}
												>
													<Box className='relative'>
														<Box className='absolute top-0 left-0 py-1 px-2 font-bold text-white bg-black uppercase text-xs rounded-2xl z-10'>
															{shoe.manufacturer}
														</Box>
														<img
															src={`${externalPublicMediaUrl}${shoe.avatar}`}
															className={`w-[100px] max-w-full aspect-1 homeProductFED${i}`}
														/>
													</Box>
													<Box className='flex flex-col justify-between items-end flex-grow gap-4'>
														<Box className='flex flex-col items-end text-end'>
															<span className='font-bold text-2xl mb-2'>{shoe.name}</span>
															<span className='text-xl'>€ {shoe.price.toFixed(2)}</span>
														</Box>
														<Box className='flex gap-2 items-center'>
															<PrimaryBlackTextButton>
																<i className='fa-regular fa-eye' />
															</PrimaryBlackTextButton>
															<PrimaryBlackButton
																onClick={() => {
																	animateProductImg(`.homeProductFEDBox${i}`, `.homeProductFED${i}`);
																	dispatcher(
																		productCartActions.add_product_to_cart({
																			name: shoe.name,
																			SKU: shoe.SKU,
																			currency: shoe.currency,
																			price: shoe.price,
																			amountToAdd: 1,
																			userID: userInfo.ID,
																		}),
																	);
																}}
															>
																<i className='fa-regular fa-cart-plus' />
															</PrimaryBlackButton>
														</Box>
													</Box>
												</Box>
											</>
										) : (
											<></>
										)}
									</Fragment>
								))}
							</Box>
						</Box>
					</Box>
					<Box className='w-full lg:w-[30%]'>
						<img
							data-aos='fade-up'
							src={`${externalPublicMediaUrl}/img/male-foot-with-brown-leather-shoes-jeans_result.webp`}
							className='w-full h-full object-contain object-center'
						/>
					</Box>
				</Box>
			</BodySingleBlock>
			<BodySingleBlock className='mb-[100px]'>
				<Box className='flex flex-col gap-16'>
					<HomepageSectionTitle id='homeAboutUs' data-aos='fade-up'>
						About us
					</HomepageSectionTitle>
					<Box className='flex flex-col-reverse md:flex-row gap-8 md:items-center'>
						<Box className='text-lg font-semibold'>
							<Box data-aos='fade-up' className='text-center md:text-start w-full md:w-[400px] lg:w-[500px] max-w-full'>
								<p>
									We are a small start-up based in the United Kingdom. We believe that technology should be accessible
									to all, so we have created some great software for home users and businesses alike.
								</p>
								<p>
									Our shoes are designed to be stylish and comfortable, so you can feel great in them all day long. We
									have a wide selection of styles for men and women, from casual to dressy. We sell all kinds of shoes
									online: athletic running shoes, trail running shoes, tennis shoes, flip flops (for those hot days),
									sandals—and even those fancy high heels that you see in fashion magazines!
								</p>
							</Box>
						</Box>
						<Box data-aos='fade-up' className='flex-grow'>
							<video
								className='w-full rounded-[30px] aspect-video object-cover'
								autoPlay
								loop
								preload='metadata'
								playsInline
								muted
								data-hd='true'
								controls={true}
							>
								<source src={`${externalPublicMediaUrl}/img/shopping-vid-43bjk2.mp4`} type='video/mp4' />
								Your browser doesn't support HTML5 video tag.
							</video>
						</Box>
					</Box>
				</Box>
			</BodySingleBlock>
			<BodySingleBlock className='mb-[100px]'>
				<Box className='flex flex-col gap-16'>
					<HomepageSectionTitle data-aos='fade-up'>All products</HomepageSectionTitle>
					<Box className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8'>
						{ShoeCollectionOne.map((shoe, i) => (
							<Fragment key={i}>
								<Box
									data-aos='fade-up'
									data-aos-delay={i * 200}
									className={`w-full flex justify-center homeProductAUBox${i}`}
								>
									<Box className='w-[250px] max-w-full p-4 rounded-2xl flex flex-col gap-4 cursor-pointer hover:shadow-[rgba(149,157,165,0.2)_0px_8px_24px] text-end'>
										<Box className='relative'>
											<Box className='absolute top-0 left-0 py-1 px-2 font-bold text-white bg-black uppercase text-xs rounded-2xl z-10'>
												{shoe.manufacturer}
											</Box>
											<Tilt>
												<img
													src={`${externalPublicMediaUrl}${shoe.avatar}`}
													className={`w-full h-[150px] object-contain homeProductAU${i}`}
												/>
											</Tilt>
										</Box>
										<span className='text-lg sm:text-2xl font-bold'>{shoe.name}</span>
										<span>€ {shoe.price.toFixed(2)}</span>
										<Box className='w-full rounded-[10px] bg-black font-bold text-white flex justify-center items-center uppercase py-2 px-4 homeAddToCardBtnA'>
											<Box className='flex items-center cartIcon'>
												<i className='fa-regular fa-cart-plus' />
											</Box>
											<Box
												className='flex items-center min-h-[30px] cursor-pointer text-sm sm:text-lg'
												onClick={() => {
													animateProductImg(`.homeProductAUBox${i}`, `.homeProductAU${i}`);
													dispatcher(
														productCartActions.add_product_to_cart({
															name: shoe.name,
															SKU: shoe.SKU,
															currency: shoe.currency,
															price: shoe.price,
															amountToAdd: 1,
															userID: userInfo.ID,
														}),
													);
												}}
											>
												<span>Add to cart</span>
											</Box>
										</Box>
									</Box>
								</Box>
							</Fragment>
						))}
					</Box>
				</Box>
			</BodySingleBlock>
		</>
	);
};
