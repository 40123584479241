import { favIconBlockDefaultVal, FavIconBlockInfo } from './favIconInfo.defaultValue';
import { MainNavMenuContent, mainNavMenuContentDefaultVal } from './mainNavMenu.defaultValue';
import { PageMainLogo, pageMainLogoDefaultValue } from './pageMainLogo.defaultValue';

export enum OverallInfoPositions {
	anywhere = 'anywhere',
}

export enum OverallInfoItemNameList {
	favicon = 'favicon',
	mainNavMenuItems = 'mainNavMenuItems',

	pageMainLogo = 'pageMainLogo',
}

export type SingleLangOverallInfoDetail = {
	[OverallInfoItemNameList.favicon]: FavIconBlockInfo;
	[OverallInfoItemNameList.mainNavMenuItems]: MainNavMenuContent[];
	[OverallInfoItemNameList.pageMainLogo]: PageMainLogo;
};

export type ConnectDetailsAndNameList<T extends OverallInfoItemNameList> = {
	// On error 'Type 'key' cannot be used to index type 'SingleLangOverallInfoDetail' please check if SingleLangOverallInfoDetail has all properties of OverallInfoItemNameList
	[key in T]: SingleLangOverallInfoDetail[key];
};

export const single_lang_overall_info_detail_init_value: ConnectDetailsAndNameList<OverallInfoItemNameList> = {
	pageMainLogo: pageMainLogoDefaultValue,
	mainNavMenuItems: mainNavMenuContentDefaultVal,
	favicon: favIconBlockDefaultVal,
};
