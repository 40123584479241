import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SingleCountry {
	name: {
		common: string;
	};
	cca2: string;
	flag: string;
}

export const defaultSingleCountry: SingleCountry = {
	cca2: '',
	flag: '',
	name: { common: '' },
};

export interface AllCountriesState {
	countries: SingleCountry[];
}

const allCountries_initial_state: AllCountriesState = {
	countries: [],
};

export const allCountriesSlice = createSlice({
	name: 'allCountries',
	initialState: allCountries_initial_state,
	reducers: {
		set_all_countries_info: (state, action: PayloadAction<Partial<AllCountriesState>>) => {
			Object.assign(state, action.payload);
		},
	},
});

// Action creators are generated for each case reducer function
export const allCountriesActions = allCountriesSlice.actions;
