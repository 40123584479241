import React from 'react';
import { UI_Snackbar } from '../UI_Components/UI_Snackbar';
import { UI_Dialog } from '../UI_Components/UI_Dialog';
import { Box, BoxProps } from '@mui/material';
import { LandingPageHeader } from '../LandingPage/LandingPageHeader';
import { Outlet } from 'react-router-dom';
import { LandingPageLeftSidebar } from '../LandingPage/leftSidebar/landingPageLeftSidebar';
import { CartRightSidebar } from '../LandingPage/CartRightSidebar';
import { RequestUserInfo } from '../authentication/request_userinfo';
import { MainFooter } from '../footer/MainFooter';

export const AppCoreLayout = React.forwardRef(function (props: BoxProps, ref: React.ForwardedRef<HTMLElement>) {
	return (
		<>
			<UI_Snackbar />
			<UI_Dialog />
			<CartRightSidebar />
			<RequestUserInfo />
			<Box ref={ref} {...props} className='w-screen flex justify-start md:justify-center'>
				<Box>
					<LandingPageLeftSidebar />
				</Box>
				<Box className='relative bg-white flex-grow max-w-screen-xl min-h-screen flex flex-col justify-between'>
					<LandingPageHeader />
					<Outlet />
					<MainFooter />
				</Box>
			</Box>
			<img src='/img/4850037_result.webp' className='fixed w-screen h-screen object-cover top-0 left-0 -z-40' />
		</>
	);
});
