import React from 'react';
import { UserRoles } from './interface/user_info.interface';
import { api_post_data } from '../environments/api_handler';

function role_expansion(role: UserRoles) {
	switch (role) {
		case UserRoles.guest:
			return [UserRoles.guest, UserRoles.admin, UserRoles.subscriber];
		case UserRoles.subscriber:
			return [UserRoles.admin, UserRoles.subscriber];
		case UserRoles.admin:
			return [UserRoles.admin];
	}
}

export interface NavMenuItem {
	name: React.ReactElement;
	icon: React.ReactElement | null;
	link: string;
	cat?: number;
	highlightPath: string[];
	action: () => void;
	include_roles: string[];
	exclude_roles: string[];
	auth_nav?: boolean;
	on_dashboard?: boolean;
}

export const menuItems: NavMenuItem[] = [
	{
		name: <>Home</>,
		icon: <i className='fa-regular fa-house-heart' />,
		link: '/',
		highlightPath: ['/'],
		cat: 1,
		auth_nav: true,
		action: () => {
			return 'ok';
		},
		include_roles: role_expansion(UserRoles.guest),
		exclude_roles: [],
	},
	{
		name: <>Check out</>,
		icon: <i className='fa-regular fa-credit-card' />,
		link: '/check-out',
		highlightPath: ['/check-out'],
		cat: 1,
		action: () => {
			return 'ok';
		},
		include_roles: role_expansion(UserRoles.guest),
		exclude_roles: [],
	},
	{
		name: <>Sign out</>,
		icon: <i className='fa-regular fa-right-from-bracket' />,
		link: '',
		cat: 1,
		highlightPath: [],
		auth_nav: true,
		action: async () => {
			await api_post_data('auth/log_out');
			window.location.href = '/';
			return 'ok';
		},
		include_roles: role_expansion(UserRoles.subscriber),
		exclude_roles: [],
	},
	{
		name: <>Sign In</>,
		icon: <i className='fa-regular fa-arrow-right-to-bracket' />,
		link: '/sign-in',
		highlightPath: ['/sign-in'],
		cat: 1,
		action: () => {
			return 'ok';
		},
		include_roles: role_expansion(UserRoles.guest),
		exclude_roles: role_expansion(UserRoles.subscriber),
	},
	// {
	// 	name: <>Blog</>,
	// 	icon: <i className='fa-regular fa-gauge' />,
	// 	link: '/blog',
	// 	cat: 4,
	// 	action: () => {
	// 		return 'ok';
	// 	},
	// 	include_roles: role_expansion(UserRoles.admin),
	// 	exclude_roles: [],
	// },
	// {
	// 	name: <>Danh mục</>,
	// 	icon: <i className='fa-regular fa-folders' />,
	// 	link: '/category',
	// 	cat: 2,
	// 	action: () => {
	// 		return 'ok';
	// 	},
	// 	include_roles: role_expansion(UserRoles.admin),
	// 	exclude_roles: [],
	// },
	// {
	// 	name: <>Layout</>,
	// 	icon: <i className='fa-regular fa-house' />,
	// 	link: '/layout',
	// 	cat: 2,
	// 	action: () => {
	// 		return 'ok';
	// 	},
	// 	include_roles: role_expansion(UserRoles.admin),
	// 	exclude_roles: role_expansion(UserRoles.guest),
	// },
	// {
	// 	name: <>Sửa Landing Page</>,
	// 	icon: <i className='fa-regular fa-house' />,
	// 	link: '/layout/landing-page',
	// 	cat: 2,
	// 	action: () => {
	// 		return 'ok';
	// 	},
	// 	include_roles: role_expansion(UserRoles.admin),
	// 	exclude_roles: [],
	// },
	// {
	// 	name: <>Thông tin chung</>,
	// 	icon: <i className='fa-brands fa-creative-commons-nd' />,
	// 	link: '/layout/page-maininfo',
	// 	cat: 2,
	// 	action: () => {
	// 		return 'ok';
	// 	},
	// 	include_roles: role_expansion(UserRoles.admin),
	// 	exclude_roles: [],
	// },
	// {
	// 	name: <>Thêm bài viết</>,
	// 	icon: <i className='fa-regular fa-plus' />,
	// 	link: '/blog/add-new-post',
	// 	cat: 4,
	// 	action: () => {
	// 		return 'ok';
	// 	},
	// 	include_roles: role_expansion(UserRoles.admin),
	// 	exclude_roles: [],
	// },
	// {
	// 	name: <>Sửa bài viết</>,
	// 	icon: <i className='fa-regular fa-pen-to-square' />,
	// 	link: '/blog/edit',
	// 	cat: 4,
	// 	action: () => {
	// 		return 'ok';
	// 	},
	// 	include_roles: [],
	// 	exclude_roles: role_expansion(UserRoles.admin),
	// },
];

export interface MenuItemCategoryEntity {
	id: number;
	name: React.ReactElement;
	order: number;
	icon: React.ReactElement;
}

export const menuItem_Categories: MenuItemCategoryEntity[] = [
	{
		id: 1,
		order: 100,
		name: <>Tổng quan</>,
		icon: <i className='fa-regular fa-chart-network' />,
	},
	{
		id: 2,
		order: 15,
		name: <>Quản lý</>,
		icon: <i className='fa-regular fa-bars-progress' />,
	},
	{
		id: 4,
		order: 35,
		name: <>Blog</>,
		icon: <i className='fa-regular fa-square-rss' />,
	},
];
