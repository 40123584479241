import { all } from 'redux-saga/effects';
import { watchAllUserEffect } from './all_users';

function* helloSaga() {
	console.log('Hello World, i am Saga worker!');
	yield 'Hello World';
}

// notice how we now only export the rootSaga
// single entry point to start all Sagas at once
export default function* rootSaga() {
	yield all([helloSaga(), watchAllUserEffect()]);
}
