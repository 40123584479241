import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface BlogPostCategoryDetails {
	ID?: number;
	name: string;
	order: number;
}

export interface BlogPostCategoryInfoState {
	details: BlogPostCategoryDetails;
	touched: boolean;
}

export const blogpost_category_initial_state: BlogPostCategoryInfoState = {
	details: {
		ID: undefined,
		name: '',
		order: 0,
	},
	touched: false,
};

export const BlogPost_CategoryInfo_Slice = createSlice({
	name: 'blogpost_category_info',
	initialState: blogpost_category_initial_state,
	reducers: {
		set_blog_post_category_state: (state, action: PayloadAction<Partial<BlogPostCategoryInfoState>>) => {
			Object.assign(state, action.payload);
		},
		set_blog_post_category_details: (state, action: PayloadAction<Partial<BlogPostCategoryDetails>>) => {
			Object.assign(state.details, action.payload);
		},
		reset_blog_post_category_state: (state) => {
			Object.assign(state, blogpost_category_initial_state);
		},
	},
});

// Action creators are generated for each case reducer function
export const blogPostCategoryInfoActions = BlogPost_CategoryInfo_Slice.actions;
