import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface BreadCrumbState {
	isDynamic: boolean;
	dynamic_pathname: string;
	homepage_link: string;
	homepage_name: string;
}

const screenSize_initial_state: BreadCrumbState = {
	homepage_link: '',
	homepage_name: '',
	isDynamic: false,
	dynamic_pathname: '',
};

export const breadCrumbSlice = createSlice({
	name: 'breadCrumb',
	initialState: screenSize_initial_state,
	reducers: {
		set_breadcrumb_info: (state, action: PayloadAction<Partial<BreadCrumbState>>) => {
			Object.assign(state, action.payload);
		},
	},
});

// Action creators are generated for each case reducer function
export const breadCrumbActions = breadCrumbSlice.actions;
