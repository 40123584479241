import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { AppCoreLanguages } from '../app_core_state.reducer';
import {
	ConnectDetailsAndNameList,
	OverallInfoItemNameList,
	single_lang_overall_info_detail_init_value,
	SingleLangOverallInfoDetail,
} from './valueInitialization/app_overall_info_init_value';

export type AppOverallInfoState = {
	[key in AppCoreLanguages]: ConnectDetailsAndNameList<OverallInfoItemNameList>;
};

export const app_overall_info_initial_state: AppOverallInfoState = {
	[AppCoreLanguages.viVN]: single_lang_overall_info_detail_init_value,
};

export const App_OverallInfo_Slice = createSlice({
	name: 'app_overall_info',
	initialState: app_overall_info_initial_state,
	reducers: {
		set_app_overall_info_state: (state, action: PayloadAction<Partial<AppOverallInfoState>>) => {
			Object.assign(state, action.payload);
		},
		set_app_overall_info_lang_nonArray_content: (
			state: Draft<AppOverallInfoState>,
			action: PayloadAction<{
				lang: AppCoreLanguages;
				itemName: OverallInfoItemNameList;
				content: Partial<SingleLangOverallInfoDetail>;
			}>,
		) => {
			const raw = action.payload;
			const foundItem = state[raw.lang][raw.itemName];
			if (typeof foundItem !== 'object') {
				alert('[Set app overall info] Error: Target obj is not an object');
				return;
			}
			const newItem = raw.content[raw.itemName];
			if (!newItem || typeof newItem !== 'object' || Array.isArray(newItem)) {
				alert('[Set app overall info] Error: New content not found or is not an object');
				return;
			}
			Object.assign(state[action.payload.lang][raw.itemName], newItem);
		},
		set_app_overall_info_lang_array_content: (
			state: Draft<AppOverallInfoState>,
			action: PayloadAction<{
				lang: AppCoreLanguages;
				itemName: OverallInfoItemNameList;
				itemID: string;
				data: { [key: string]: any };
			}>,
		) => {
			const raw = action.payload;
			const allItems: any = state[raw.lang][raw.itemName];
			if (!Array.isArray(allItems)) {
				alert('[Set app overall info] Error: Target obj is not an array');
				return;
			}
			const found = allItems.find((item) => item.ID === raw.itemID);
			if (!found) {
				alert('[Set app overall info] Error: Item to be updated not found.');
				return;
			}
			Object.assign(found, raw.data);
			Object.assign(state[action.payload.lang], {
				[raw.itemName]: allItems,
			});
		},
		add_array_block_item: (
			state: Draft<AppOverallInfoState>,
			action: PayloadAction<{
				lang: AppCoreLanguages;
				itemName: OverallInfoItemNameList;
				newValue: any;
			}>,
		) => {
			const raw = action.payload;
			const allBlocks = state[raw.lang][raw.itemName];
			if (!allBlocks || !Array.isArray(allBlocks)) {
				alert(`Target array ${raw.itemName} to be added is not an array or not found`);
				return;
			}
			Object.assign(state[raw.lang], {
				[raw.itemName]: [...allBlocks, raw.newValue],
			});
		},
		delete_array_block_item: (
			state: Draft<AppOverallInfoState>,
			action: PayloadAction<{
				lang: AppCoreLanguages;
				itemName: OverallInfoItemNameList;
				itemID: string;
			}>,
		) => {
			const raw = action.payload;
			const allBlocks = state[raw.lang][raw.itemName];
			if (!allBlocks || !Array.isArray(allBlocks)) {
				alert(`Target array ${raw.itemName} to be edited is not an array or not found`);
				return;
			}
			Object.assign(state[raw.lang], {
				[raw.itemName]: [...allBlocks].filter((item) => item.ID !== raw.itemID),
			});
		},
	},
});

// Action creators are generated for each case reducer function
export const appOverallInfoActions = App_OverallInfo_Slice.actions;
