import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store/core.store';
import { appToolsActions } from '../../store/reducers/appTools.reducer';

const MyDialog = styled(Dialog)(() => ({
	'& .MuiDialog-paper': {
		overflow: 'initial',
		maxWidth: '90vw',
		boxShadow: 'none',
	},
}));

export const UI_Dialog = () => {
	const dispatcher = useDispatch();
	const content = useSelector((e: AppState) => e.appTools.dialogContent);
	const isOpen = useSelector((e: AppState) => e.appTools.dialog_isOpen);

	return (
		<div>
			<MyDialog
				aria-labelledby='customized-dialog-title'
				open={isOpen}
				onClose={() => {
					dispatcher(
						appToolsActions.set_apptools_info({
							dialog_isOpen: false,
						}),
					);
				}}
				sx={{
					'& *': {
						maxWidth: '100%',
					},
				}}
			>
				{content}
			</MyDialog>
		</div>
	);
};
