import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { api_post_data } from '../../environments/api_handler';
import { ShopCurrency } from './app_core_state.reducer';
import debounce from 'lodash.debounce';
import { BillbeeShopSourceId } from '../../environments/environments';

export interface ProductCartSingleItem {
	SKU: string;
	price: number;
	currency: ShopCurrency;
	quantity: number;
	name: string;
}

export interface ProductCartState {
	items: ProductCartSingleItem[];
}

const localCartItemsKey = 'productCartInfo';
const cartLocalItemsVal = window.localStorage.getItem(localCartItemsKey);
const localInitCartItems = cartLocalItemsVal ? JSON.parse(cartLocalItemsVal) : [];

const productCart_initial_state: ProductCartState = {
	items: localInitCartItems,
};

const cartApiDebouncer = debounce((val: any[]) => {
	api_post_data('user/edit_cart', {
		shop_name: BillbeeShopSourceId,
		items: val,
	}).catch();
}, 1000);

export const productCartSlice = createSlice({
	name: 'productCart',
	initialState: productCart_initial_state,
	reducers: {
		reset_cartItems: (state) => {
			Object.assign(state, { items: [] });
			window.localStorage.removeItem(localCartItemsKey);
		},
		set_cart_info: (state, action: PayloadAction<Partial<ProductCartState>>) => {
			Object.assign(state, action.payload);
		},
		add_product_to_cart: (
			state,
			action: PayloadAction<{
				SKU: string;
				price: number;
				currency: ShopCurrency;
				amountToAdd: number;
				userID: number;
				name: string;
			}>,
		) => {
			const raw = action.payload;
			const initVal: ProductCartSingleItem | undefined = state.items.find((item) => item.SKU === raw.SKU);
			if (!initVal) {
				const newVal: ProductCartSingleItem = {
					name: raw.name,
					SKU: raw.SKU,
					currency: raw.currency,
					price: raw.price,
					quantity: 0,
				};
				state.items.push(newVal);
			}
			const found = state.items.find((item) => item.SKU === raw.SKU);
			if (!found) {
				console.log('[add_product_to_cart] Add product failed');
				return;
			}
			const newQuantity = found.quantity + raw.amountToAdd;
			if (newQuantity > 0) {
				// Declare all val to avoid missing properties when codes change.
				const newItemWithQuantity: ProductCartSingleItem = {
					name: raw.name,
					SKU: raw.SKU,
					currency: raw.currency,
					price: raw.price,
					quantity: newQuantity >= 0 ? newQuantity : 0,
				};
				Object.assign(found, { ...newItemWithQuantity });
			} else {
				state.items.splice(
					state.items.findIndex((item) => item.SKU === raw.SKU),
					1,
				);
			}
			if (raw.userID === 0) {
				window.localStorage.setItem(localCartItemsKey, JSON.stringify(state.items));
			} else {
				const cartItems: any[] = current(state.items);
				cartApiDebouncer.call('', cartItems);
			}
		},
	},
});

// Action creators are generated for each case reducer function
export const productCartActions = productCartSlice.actions;
