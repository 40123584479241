import React from 'react';
import 'reflect-metadata';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './App.scss';
import { createTheme, ThemeProvider } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { Window_Resize_Detector } from './components/shared/window_resize';
import AOS from 'aos';
import rootSaga from './store/saga/core_saga';
import { AppState, sagaMiddleware } from './store/core.store';
import { useSelector } from 'react-redux';
import { externalPublicMediaUrl } from './environments/environments';

function App() {
	AOS.init({
		duration: 1000,
		once: true,
	});
	sagaMiddleware.run(rootSaga);

	const theme_options = useSelector((e: AppState) => e.app_core_state.theme_options_client_FE);

	const theme = createTheme(theme_options);

	return (
		<ThemeProvider theme={theme}>
			<HelmetProvider>
				<Helmet>
					<title>Mantic Shop</title>
					<link rel='icon' href={`${externalPublicMediaUrl}/img/favicon-bh42.ico`} />
					<meta name='description' content='E-Commerce product with Paypal payment and Billbee invoice system.' />

					<meta property='og:url' content='https://mantic.censolut.com' />
					<meta property='og:type' content='website' />
					<meta property='og:title' content='Mantic E-Commerce Product' />
					<meta
						property='og:description'
						content='E-Commerce product with Paypal payment and Billbee invoice system.'
					/>
					<meta property='og:image' content={`${externalPublicMediaUrl}/img/screen-4bhjk23.webp`} />
					<meta property='og:video' content={`${externalPublicMediaUrl}/img/My-Movie-42424.swf`} />

					<meta name='twitter:card' content='summary_large_image' />
					<meta name='twitter:title' content='Mantic E-Commerce Product' />
					<meta
						name='twitter:description'
						content='E-Commerce product with Paypal payment and Billbee invoice system.'
					/>
					<meta name='twitter:image' content={`${externalPublicMediaUrl}/img/screen-4bhjk23.webp`} />
				</Helmet>
				<div className='App prose'>
					<Window_Resize_Detector />
					<Outlet />
				</div>
			</HelmetProvider>
		</ThemeProvider>
	);
}

export default App;
