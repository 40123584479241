import * as React from 'react';
import { createBrowserRouter, Outlet, RouteObject } from 'react-router-dom';
import App from '../App';
import { AppCoreLayout } from '../components/layouts/AppCoreLayout';
import { LandingHomePage } from '../components/LandingPage/LandingHomePage';
import { CheckOutPage } from '../components/LandingPage/CheckOutPage';
import { BillbeePaypalPayment } from '../components/payment/BillbeePaypalPayment';
import { LoginPage } from '../components/authentication/loginPage';
import { UserProfilePage } from '../components/LandingPage/UserProfilePage';
import { BillbePaypaySuccessMessage } from '../components/payment/PaymentSuccess';

export const appClientRouter: RouteObject[] = [
	{
		index: true,
		element: <LandingHomePage />,
	},
	{
		path: '/sign-in',
		element: <LoginPage />,
	},
	{
		path: '/user-profile',
		element: <UserProfilePage />,
	},
	{
		path: '/check-out',
		element: <Outlet />,
		children: [
			{
				index: true,
				element: <CheckOutPage />,
			},
			{
				path: 'payment',
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <BillbeePaypalPayment />,
					},
					{
						path: 'success',
						element: <BillbePaypaySuccessMessage />,
					},
				],
			},
		],
	},
];

export const indexRouter = createBrowserRouter([
	{
		path: '/',
		element: <App />,
		children: [
			{
				path: '/',
				element: <AppCoreLayout />,
				children: appClientRouter,
			},
		],
	},
]);
