import { UserInfo } from '../../models/interface/user_info.interface';
import { gql_queries } from '../../models/interface/gql_queries';
import { call, put, takeLatest } from 'redux-saga/effects';
import { graphQLclient } from '../../models/graphQL_Client';

export const getAllUsers = () => ({
	type: 'GET_ALL_USERS',
});

export const setAllUsers = ({ all_users }: { all_users: UserInfo[] }) => ({
	type: 'SET_ALL_USERS',
	all_users,
});

export interface AllUsersState {
	all_users: UserInfo[];
}

const initialState: AllUsersState = {
	all_users: [],
};

export const allUsersReducer = (state = initialState, action: any) => {
	switch (action.type) {
		case 'SET_ALL_USERS':
			console.log(action.all_users);
			return { ...state, all_users: action.all_users };
		default:
			return state;
	}
};

export function requestAllUsers() {
	return graphQLclient.query({
		query: gql_queries.all_users,
	});
}

export function* getAllUsers_action() {
	try {
		const { data } = yield call(requestAllUsers);
		if (!data.all_users) {
			return new Error('All users query invalid');
		}
		yield put(setAllUsers({ all_users: data.all_users }));
	} catch (e: any) {
		console.log(e.message);
	}
}

export function* watchAllUserEffect() {
	yield takeLatest('GET_ALL_USERS', getAllUsers_action);
}
