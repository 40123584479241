import { BodySingleBlock } from '../layouts/BodySingleBlock';
import { Autocomplete, Box, Chip, TextField } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { appToolsActions, headerStyleInitValue } from '../../store/reducers/appTools.reducer';
import styled from '@emotion/styled';
import { BillbeeShopSourceId, externalCdnUrl } from '../../environments/environments';
import { AppState } from '../../store/core.store';
import { ShoeCollectionOne, SingleShoe } from '../shared/ProductList';
import { ConfirmOrderBlock, ConfirmOrderBlockTitle, SingleTitle } from './CheckOutPage';
import { UserRoles } from '../../models/interface/user_info.interface';
import { PrimaryBlackButton } from '../UI_Components/UI_Buttons';
import { api_post_data } from '../../environments/api_handler';
import { BillingAddressForm } from './form/billingAddressForm';
import { ShippingAddressForm } from './form/shippingAddressForm';
import { AddressTypes, InvoiceServices } from '../../store/reducers/checkout_info.reducer';
import { GetCountryList } from './form/GetCountryList';

const ShapeDivider = () => {
	const Result = styled(Box)`
		.custom-shape-divider-bottom-1668331215 {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			overflow: hidden;
			line-height: 0;
			transform: rotate(180deg);
		}

		.custom-shape-divider-bottom-1668331215 svg {
			position: relative;
			display: block;
			width: calc(100% + 1.3px);
			height: 100px;
		}

		.custom-shape-divider-bottom-1668331215 .shape-fill {
			fill: #ffffff;
		}
	`;

	return (
		<>
			<Result>
				<div className='custom-shape-divider-bottom-1668331215'>
					<svg data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1200 120' preserveAspectRatio='none'>
						<path
							d='M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z'
							opacity='.25'
							className='shape-fill'
						/>
						<path
							d='M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z'
							opacity='.5'
							className='shape-fill'
						/>
						<path
							d='M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z'
							className='shape-fill'
						/>
					</svg>
				</div>
			</Result>
		</>
	);
};

const ProductSelector = () => {
	const dispatcher = useDispatch();
	const currentProduct = useSelector((e: AppState) => e.appTools.productToUpdate);

	return (
		<>
			<Box className='flex-grow'>
				<Autocomplete
					options={ShoeCollectionOne}
					getOptionLabel={(option: SingleShoe) => option.name}
					clearOnEscape
					inputValue={currentProduct.name}
					clearOnBlur={false}
					renderOption={(props, option: SingleShoe) => (
						<Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
							{option.name}
						</Box>
					)}
					onInputChange={(e, value) => {
						dispatcher(
							appToolsActions.set_apptools_info({
								productToUpdate: {
									...currentProduct,
									name: value,
								},
							}),
						);
					}}
					onChange={(e, value) => {
						if (!value) return;
						dispatcher(
							appToolsActions.set_apptools_info({
								productToUpdate: value,
							}),
						);
					}}
					renderInput={(params) => <TextField {...params} label='Product' />}
				/>
			</Box>
			<PrimaryBlackButton
				onClick={() => {
					api_post_data('billbee/add_new_product', { ...currentProduct, SourceId: BillbeeShopSourceId }).catch();
				}}
			>
				<i className='fa-regular fa-plus mr-2' /> Add product
			</PrimaryBlackButton>
		</>
	);
};

const CurrentBillbeeProducts = () => {
	const [allProducts, setAllProducts] = useState<any[]>([]);

	useEffect(() => {
		api_post_data('billbee/get_all_products', {
			SourceId: BillbeeShopSourceId,
		})
			.then((res) => {
				setAllProducts(res.data);
			})
			.catch((e) => console.log(e));
	}, []);

	return (
		<>
			<Box className='flex flex-wrap gap-4'>
				{allProducts.map((item, i) => (
					<Fragment key={i}>
						<Chip
							label={`${item.SKU} - ${item.Title ? item.Title.find((tt: any) => tt.LanguageCode === 'EN')?.Text : ''}`}
						/>
					</Fragment>
				))}
			</Box>
		</>
	);
};

const AdminToolBox = () => {
	return (
		<BodySingleBlock>
			<Box className='flex flex-col gap-8'>
				<SingleTitle>Admin Tool box</SingleTitle>
				<Box className='grid grid-cols-1 md:grid-cols-2'>
					<Box className='flex flex-col gap-4'>
						<Box className='flex gap-4'>
							<ProductSelector />
						</Box>
						<span className='font-bold'>Current Billbee Products</span>
						<CurrentBillbeeProducts />
					</Box>
					<Box />
				</Box>
			</Box>
		</BodySingleBlock>
	);
};

const UpdateBillingAddressButton = () => {
	const checkout_info = useSelector((e: AppState) => e.checkout_info);
	return (
		<PrimaryBlackButton
			onClick={async () => {
				await api_post_data('user/edit_address', {
					type: AddressTypes.billing,
					service: InvoiceServices.billbee,
					content: checkout_info.billingAddress,
				});
			}}
		>
			<Box className='flex gap-4 items-center py-2'>
				<i className='fa-regular fa-floppy-disk mr-2 text-2xl' /> Save
			</Box>
		</PrimaryBlackButton>
	);
};

const UpdateShippingAddressButton = () => {
	const checkout_info = useSelector((e: AppState) => e.checkout_info);
	return (
		<PrimaryBlackButton
			onClick={async () => {
				await api_post_data('user/edit_address', {
					type: AddressTypes.shipping,
					service: InvoiceServices.billbee,
					content: checkout_info.shippingAddress,
				});
			}}
		>
			<Box className='flex gap-4 items-center py-2'>
				<i className='fa-regular fa-floppy-disk mr-2 text-2xl' /> Save
			</Box>
		</PrimaryBlackButton>
	);
};

export const UserProfilePage = () => {
	const dispatcher = useDispatch();
	useEffect(() => {
		dispatcher(
			appToolsActions.set_apptools_info({
				headerBg: 'transparent',
				headerPosition: 'absolute',
				headerTextColor: 'white',
			}),
		);
		return () => {
			dispatcher(appToolsActions.set_apptools_info(headerStyleInitValue));
		};
	});

	const userInfo = useSelector((e: AppState) => e.user_info.data);

	return (
		<Box className='flex flex-col gap-[100px]'>
			<GetCountryList />
			<BodySingleBlock className='px-0'>
				<Box className='relative py-[100px] min-h-[400px] bg-gradient-to-br from-pink-200 via-violet-200 to-cyan-200'>
					<ShapeDivider />
				</Box>
				<Box className='flex flex-col gap-16 px-4 items-center mt-[-100px] mb-[100px]'>
					<img
						src={`${externalCdnUrl}/userDefaultAvatar.svg`}
						className='z-[1] w-[200px] rounded-full border-2 border-white bg-gradient-to-br from-violet-500 to-cyan-500'
					/>
					<Box className='font-black text-4xl'>Welcome, {userInfo.email.split('@')[0]}!</Box>
				</Box>
			</BodySingleBlock>
			{userInfo.role === UserRoles.admin ? (
				<>
					<AdminToolBox />
				</>
			) : (
				<></>
			)}
			<BodySingleBlock>
				<Box className='flex flex-col gap-8'>
					<SingleTitle>Your address:</SingleTitle>
					<Box className='grid grid-cols-1 gap-8 md:grid-cols-2'>
						<ConfirmOrderBlock>
							<ConfirmOrderBlockTitle>
								<i className='fa-regular fa-address-card mr-4' />
								Billing address
							</ConfirmOrderBlockTitle>
							<BillingAddressForm />
							<Box className='flex justify-end'>
								<UpdateBillingAddressButton />
							</Box>
						</ConfirmOrderBlock>
						<ConfirmOrderBlock>
							<ConfirmOrderBlockTitle>
								<i className='fa-regular fa-truck-fast mr-4' />
								Shipping address
							</ConfirmOrderBlockTitle>
							<ShippingAddressForm />
							<Box className='flex justify-end'>
								<UpdateShippingAddressButton />
							</Box>
						</ConfirmOrderBlock>
					</Box>
				</Box>
			</BodySingleBlock>
		</Box>
	);
};
