import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum all_screen_size {
	xs = 'xs',
	sm = 'sm',
	md = 'md',
	lg = 'lg',
	xl = 'xl',
	xxl = 'xxl',
}

export interface ScreenSizeState {
	value: all_screen_size;
}

const screenSize_initial_state: ScreenSizeState = {
	value: all_screen_size.xs,
};

export const screenSizeSlice = createSlice({
	name: 'screenSize',
	initialState: screenSize_initial_state,
	reducers: {
		setScreenSize: (state, action: PayloadAction<all_screen_size>) => {
			state.value = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const screenSizeActions = screenSizeSlice.actions;
