import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { api_post_data } from '../../environments/api_handler';
import { Box, BoxProps } from '@mui/material';
import { ShoeCollectionOne } from '../shared/ProductList';
import { BillbeeShopSourceId, externalPublicMediaUrl, paypalClientID } from '../../environments/environments';
import { BodySingleBlock } from '../layouts/BodySingleBlock';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store/core.store';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import { productCartActions } from '../../store/reducers/productCartInfo.reducer';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { SingleTitle } from '../LandingPage/CheckOutPage';
import { appToolsActions } from '../../store/reducers/appTools.reducer';

const PaymentSummaryBlock = () => {
	const cartItems = useSelector((e: AppState) => e.product_cart.items);
	const deliveryService = useSelector((e: AppState) => e.checkout_info.deliveryService);

	const summaryPrice = useMemo(() => {
		return _.sum(cartItems.map((item) => item.price * item.quantity));
	}, [cartItems]);

	const SummaryTextItems = [
		{
			left: <span>Subtotal</span>,
			right: <span>{(summaryPrice * (100 / 119)).toFixed(2)}</span>,
		},
		{
			left: <span>VAT</span>,
			right: <span>19%</span>,
		},
		{
			left: <span>Total</span>,
			right: <span>{summaryPrice.toFixed(2)}</span>,
		},
		{
			left: <span>Shipping</span>,
			right: <span>{deliveryService.price.toFixed(2)}</span>,
		},
		{
			left: <span className='font-black uppercase'>Order Total</span>,
			right: <span className='font-black uppercase'>€ {(summaryPrice + deliveryService.price).toFixed(2)}</span>,
		},
	];
	return (
		<>
			<Box className='flex flex-col gap-4 text-xl'>
				{SummaryTextItems.map((item, i) => (
					<Box key={i} className='flex justify-between gap-4'>
						<Box className='max-w-[200px]'>{item.left}</Box>
						<Box className='max-w-[320px]'>{item.right}</Box>
					</Box>
				))}
			</Box>
		</>
	);
};

const SingleChildBlock = (props: BoxProps) => {
	return (
		<Box className='p-4 border-2 border-black rounded-2xl'>
			<Box {...props} className={'flex flex-col gap-8 ' + (props.className ? props.className : '')} />
		</Box>
	);
};

export const BillbeePaypalPayment = () => {
	const navigate = useNavigate();
	const dispatcher = useDispatch();

	const cartItems = useSelector((e: AppState) => e.product_cart.items);
	const checkout_info = useSelector((e: AppState) => e.checkout_info);

	const [api_order_id, set_api_order_id] = useState();
	useEffect(() => {
		api_post_data('billbee/paypal/createOrder', {
			orders: cartItems,
			billingAddress: checkout_info.billingAddress,
			deliveryAddress: checkout_info.shippingAddress,
			deliveryService: checkout_info.deliveryService,
		})
			.then((res) => {
				set_api_order_id(res.data.id);
			})
			.catch((e) => {
				console.log(e);
				navigate('/check-out');
			});
	}, [cartItems]);
	return (
		<Box className='py-[100px]'>
			<BodySingleBlock>
				<Box className='flex flex-col items-center lg:flex-row lg:items-start gap-8'>
					<Box className='flex-grow w-full lg:w-auto'>
						<SingleChildBlock>
							<Box className='flex flex-col gap-16'>
								<SingleTitle>Your items</SingleTitle>
								<Box className='grid grid-cols-1 md:grid-cols-2 gap-4'>
									{cartItems.map((item, i) => {
										const prodInfo = ShoeCollectionOne.find((shoe) => shoe.SKU === item.SKU);
										if (!prodInfo) return <></>;
										return (
											<Fragment key={i}>
												<Box className='flex justify-between gap-4 p-4 rounded-2xl border-2 border-slate-500/40'>
													<Box>
														<img
															src={`${externalPublicMediaUrl}${prodInfo.avatar}`}
															className='w-[100px] aspect-1 object-center object-contain'
														/>
													</Box>
													<Box className='flex-grow'>
														<Box className='flex justify-end'>
															<Box className='max-w-[220px] flex flex-col text-end'>
																<Box className='font-bold'>{prodInfo.name}</Box>
																<Box>Quantity: {item.quantity}</Box>
																<Box>Price: € {item.price.toFixed(2)}</Box>
															</Box>
														</Box>
													</Box>
												</Box>
											</Fragment>
										);
									})}
								</Box>
								<PaymentSummaryBlock />
							</Box>
						</SingleChildBlock>
					</Box>
					<Box>
						<Box className='w-[400px] max-w-[90vw]'>
							<SingleChildBlock>
								<Box className='flex flex-col gap-16'>
									<SingleTitle>Payment method</SingleTitle>
									<Box>
										{api_order_id ? (
											<>
												<PayPalScriptProvider
													options={{
														'client-id': paypalClientID,
													}}
												>
													<PayPalButtons
														createOrder={() => {
															return api_order_id;
														}}
														onApprove={async (data) => {
															const result = await api_post_data('billbee/paypal/executeOrder', {
																SourceId: BillbeeShopSourceId,
																deliveryAddress: checkout_info.shippingAddress,
																billingAddress: checkout_info.billingAddress,
																orders: cartItems,
																deliveryService: checkout_info.deliveryService,
																...data,
															});
															if (result.status == 200) {
																dispatcher(productCartActions.reset_cartItems());
																dispatcher(
																	appToolsActions.set_apptools_info({
																		invoicePath: result.data.payload.invoicePath,
																	}),
																);
																navigate('success');
															}
														}}
													/>
												</PayPalScriptProvider>
												<Box className='flex flex-col'>
													<Box>Please use this test account to pay:</Box>
													<Box>
														Username:
														<br />
														sb-bsggv20129331@personal.example.com
													</Box>
													<Box>
														Password:
														<br />
														SG&%3&es
													</Box>
												</Box>
											</>
										) : (
											<></>
										)}
									</Box>
								</Box>
							</SingleChildBlock>
						</Box>
					</Box>
				</Box>
			</BodySingleBlock>
		</Box>
	);
};
