import React, { ChangeEvent } from 'react';
import { Autocomplete, Box, BoxProps, TextField, TextFieldProps } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store/core.store';
import { checkOutActions } from '../../../store/reducers/checkout_info.reducer';
import { SingleCountry } from '../../../store/reducers/allCountries.reducer';

const BillingAddressTextInput = React.forwardRef(function (props: TextFieldProps, ref: React.ForwardedRef<any>) {
	const billingAddressInfo = useSelector((e: AppState) => e.checkout_info.billingAddress);
	const isTouched = useSelector((e: AppState) => e.appTools.inputFormIsTouched);
	const dispatcher = useDispatch();

	const itemKey = props.name;
	const addressInfo: any = billingAddressInfo;
	const itemVal = itemKey ? addressInfo[itemKey] : '';

	function handleBillingAddressForm(e: ChangeEvent<any>) {
		if (e.target.name) {
			dispatcher(
				checkOutActions.set_billing_address_info({
					[e.target.name]: e.target.value,
				}),
			);
		}
	}

	return (
		<TextField
			ref={ref}
			value={itemVal}
			onChange={handleBillingAddressForm}
			error={props.required && !itemVal && isTouched}
			{...props}
		/>
	);
});

const BillingAddressCountrySelector = () => {
	const dispatcher = useDispatch();
	const billingAddressInfo = useSelector((e: AppState) => e.checkout_info.billingAddress);
	const all_countries = useSelector((e: AppState) => e.allCountries.countries);
	const isTouched = useSelector((e: AppState) => e.appTools.inputFormIsTouched);

	return (
		<>
			<Autocomplete
				options={all_countries}
				getOptionLabel={(option: SingleCountry) => option.name.common}
				clearOnEscape
				inputValue={billingAddressInfo.Country}
				clearOnBlur={false}
				renderOption={(props, option: SingleCountry) => (
					<Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
						{option.flag} {option.name.common}
					</Box>
				)}
				onInputChange={(e, value) => {
					const foundCountry = all_countries.find(
						(country) => country.name.common.toLowerCase() === value.toLowerCase(),
					);
					dispatcher(
						checkOutActions.set_billing_address_info({
							Country: value,
							CountryISO2: foundCountry?.cca2 || '',
						}),
					);
				}}
				onChange={(e, value) => {
					if (!value) return;
					dispatcher(
						checkOutActions.set_billing_address_info({
							Country: value.name.common,
							CountryISO2: value.cca2,
						}),
					);
				}}
				renderInput={(params) => (
					<TextField error={isTouched && !billingAddressInfo.Country} {...params} label='Country' />
				)}
			/>
		</>
	);
};

export const BillingAddressForm = () => {
	const Layout2Col = (props: BoxProps) => <Box {...props} className='grid grid-cols-1 sm:grid-cols-2 gap-8' />;

	return (
		<>
			<Layout2Col>
				<BillingAddressTextInput required name='FirstName' label='First name' />
				<BillingAddressTextInput required name='LastName' label='Last name' />
			</Layout2Col>
			<BillingAddressTextInput name='Company' label='Company' />
			<Layout2Col>
				<BillingAddressTextInput required name='Email' label='Email' />
				<BillingAddressTextInput name='Phone' label='Phone number' />
			</Layout2Col>
			<Layout2Col>
				<BillingAddressTextInput required name='Street' label='Street' />
				<BillingAddressTextInput required name='HouseNumber' label='House Nr.' />
			</Layout2Col>
			<Layout2Col>
				<BillingAddressTextInput required name='Zip' label='Postal Code' />
				<BillingAddressTextInput required name='City' label='City' />
			</Layout2Col>
			<Layout2Col>
				<BillingAddressTextInput required name='State' label='State' />
				<BillingAddressCountrySelector />
			</Layout2Col>
		</>
	);
};
