import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReactNode } from 'react';
import { ShoeCollectionOne, SingleShoe } from '../../components/shared/ProductList';

export interface FormHandlerState {
	inputFormIsTouched: boolean;
	inputFormHasError: boolean;
}

export interface HeaderStyleState {
	headerPosition: 'sticky' | 'absolute';
	headerBg: string;
	headerTextColor: string;
}

export type AppToolsState = {
	leftSideBarIsOpen: boolean;
	rightSideBarIsOpen: boolean;
	is_loading: boolean;
	app_has_error: boolean;
	app_error_message: string[];
	snackbar_content: string;
	dialog_isOpen: boolean;
	dialogContent: ReactNode | null;
	productToUpdate: SingleShoe;
	invoicePath: string;
} & HeaderStyleState &
	FormHandlerState;

export const headerStyleInitValue: HeaderStyleState = {
	headerPosition: 'sticky',
	headerBg: 'white',
	headerTextColor: 'black',
};

const appTools_initial_state: AppToolsState = {
	inputFormHasError: false,
	inputFormIsTouched: false,
	snackbar_content: '',
	app_error_message: [],
	app_has_error: false,
	is_loading: false,
	leftSideBarIsOpen: false,
	rightSideBarIsOpen: false,
	dialog_isOpen: false,
	dialogContent: null,
	productToUpdate: ShoeCollectionOne[0],
	invoicePath: '',
	...headerStyleInitValue,
};

export const appToolsSlice = createSlice({
	name: 'appTools',
	initialState: appTools_initial_state,
	reducers: {
		set_apptools_info: (state, action: PayloadAction<Partial<AppToolsState>>) => {
			Object.assign(state, action.payload);
		},
	},
});

// Action creators are generated for each case reducer function
export const appToolsActions = appToolsSlice.actions;
