import { Components } from '@mui/material';

export const my_custom_components: Components<any> = {
	MuiPaper: {
		styleOverrides: {
			root: {
				'&.MuiDrawer-paper': {
					backgroundColor: 'transparent',
					backgroundImage: 'none',
					border: 'none',
				},
			},
		},
	},
};
