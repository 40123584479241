import { AppState } from '../../store/core.store';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { checkOutActions } from '../../store/reducers/checkout_info.reducer';

export const ApplyUserAddress = () => {
	const dispatcher = useDispatch();
	const userInfo = useSelector((e: AppState) => e.user_info.data);
	const billingAddress =
		userInfo.billing_address && userInfo.billing_address.length > 0 ? userInfo.billing_address[0].content : undefined;
	const shippingAddress =
		userInfo.shipping_address && userInfo.shipping_address.length > 0
			? userInfo.shipping_address[0].content
			: undefined;

	useEffect(() => {
		if (billingAddress) dispatcher(checkOutActions.set_billing_address_info(billingAddress));
		if (shippingAddress) dispatcher(checkOutActions.set_shipping_address_info(shippingAddress));
	}, [billingAddress, shippingAddress]);

	return <></>;
};
