import React, { useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { appToolsActions } from '../../store/reducers/appTools.reducer';
import { BodySingleBlock } from '../layouts/BodySingleBlock';
import { PrimaryHoverGradientBlueCyanButton } from '../UI_Components/UI_Buttons';
import { AppState } from '../../store/core.store';
import { externalPublicMediaUrl } from '../../environments/environments';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { productCartActions } from '../../store/reducers/productCartInfo.reducer';

const GetCartDataFromUser = () => {
	const dispatcher = useDispatch();
	const userInfo = useSelector((e: AppState) => e.user_info.data);
	const userCartItems = userInfo.cart && userInfo.cart.length > 0 ? userInfo.cart[0]?.items : undefined;
	useEffect(() => {
		if (!userCartItems) return;
		dispatcher(
			productCartActions.set_cart_info({
				items: userCartItems,
			}),
		);
	}, [userCartItems]);

	return <></>;
};

const HeaderCartButton = () => {
	const dispatcher = useDispatch();
	const rightSidebarIsOpen = useSelector((e: AppState) => e.appTools.rightSideBarIsOpen);
	const cartItems = useSelector((e: AppState) => e.product_cart.items);
	const totalItemsAmount = useMemo(() => {
		return _.sum(cartItems.map((item) => item.quantity));
	}, [cartItems]);
	return (
		<PrimaryHoverGradientBlueCyanButton
			id='headerCartIconBox'
			onClick={() => {
				dispatcher(
					appToolsActions.set_apptools_info({
						rightSideBarIsOpen: !rightSidebarIsOpen,
					}),
				);
			}}
		>
			<i className='fa-regular fa-cart-shopping' />
			<span className='text-sm font-bold h-fit ml-2'>{totalItemsAmount}</span>
		</PrimaryHoverGradientBlueCyanButton>
	);
};

export const LandingPageHeader: React.FC<any> = () => {
	const navigate = useNavigate();
	const dispatcher = useDispatch();
	const leftSidebarIsOpen = useSelector((e: AppState) => e.appTools.leftSideBarIsOpen);
	const headerPosition = useSelector((e: AppState) => e.appTools.headerPosition);
	const headerBg = useSelector((e: AppState) => e.appTools.headerBg);
	const headerTextColor = useSelector((e: AppState) => e.appTools.headerTextColor);

	return (
		<>
			<GetCartDataFromUser />
			<BodySingleBlock
				className='w-full top-0 left-0 z-20'
				style={{
					position: headerPosition,
				}}
			>
				<Box
					className='flex justify-between items-center py-4'
					style={{
						background: headerBg,
						color: headerTextColor,
					}}
				>
					<Box>
						<Box className='xl:hidden'>
							<img src={`${externalPublicMediaUrl}/img/logo-mantic-ebhk23.svg`} className='w-12 aspect-1' />
						</Box>
					</Box>
					<Box>
						<Box className='flex gap-4'>
							<HeaderCartButton />
							<PrimaryHoverGradientBlueCyanButton onClick={() => navigate('user-profile')}>
								<i className='fa-regular fa-user' />
							</PrimaryHoverGradientBlueCyanButton>
							<PrimaryHoverGradientBlueCyanButton
								onClick={() => {
									dispatcher(
										appToolsActions.set_apptools_info({
											leftSideBarIsOpen: !leftSidebarIsOpen,
										}),
									);
								}}
							>
								<i className='fa-regular fa-bars' />
							</PrimaryHoverGradientBlueCyanButton>
						</Box>
					</Box>
				</Box>
			</BodySingleBlock>
		</>
	);
};
