import { Box } from '@mui/material';
import { externalCdnUrl, externalPublicMediaUrl } from '../../environments/environments';
import { PrimaryBlackButton } from '../UI_Components/UI_Buttons';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/core.store';
import { useNavigate } from 'react-router-dom';

const InvoiceViewer = (props: { invoicePath: string }) => {
	return (
		<>
			{props.invoicePath ? (
				<>
					<object
						data={`${externalCdnUrl}/${props.invoicePath}`}
						type='application/pdf'
						className='w-full min-h-[80vh]'
					>
						<Box className='text-center'>
							<a href={`${externalCdnUrl}/${props.invoicePath}`} target='_blank' rel='noreferrer'>
								<i className='fa-regular fa-arrow-up-right-from-square mr-2' /> Open your invoice{' '}
							</a>
						</Box>
					</object>
				</>
			) : (
				<></>
			)}
		</>
	);
};

export const BillbePaypaySuccessMessage = () => {
	const navigate = useNavigate();
	const invoicePath = useSelector((e: AppState) => e.appTools.invoicePath);
	useEffect(() => {
		if (!invoicePath) navigate('/');
	}, [invoicePath]);

	return (
		<>
			<Box className='flex flex-col justify-center items-center gap-8'>
				<Box className='flex gap-4 items-center'>
					<img src={`${externalPublicMediaUrl}/img/icons8-celebrate-64_result.webp`} className='w-[40px] aspect-1' />
					<Box className='flex-grow'>
						<span className='text-4xl font-black text-green-500 inline-block'>Payment success</span>
					</Box>
				</Box>
				<p className='font-semibold text-center'>
					Thank you for your purchase. Your delivery will be arrived in 1-2 business day(s).
					<br />
					We've sent your order details to your email, please check your email for further information.
					<br />
					Here is your invoice:
				</p>
				<Box className='w-full'>
					<InvoiceViewer invoicePath={invoicePath} />
				</Box>
				<PrimaryBlackButton onClick={() => navigate('/')}>
					<i className='fa-regular fa-house-heart mr-2' /> Back to home
				</PrimaryBlackButton>
			</Box>
		</>
	);
};
