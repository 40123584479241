import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum InvoiceServices {
	billbee = 'billbee',
}

export enum AddressTypes {
	billing = 'billing',
	shipping = 'shipping',
}

export interface CustomerAddress {
	FirstName: string;
	LastName: string;
	Company: string;
	Street: string;
	HouseNumber: string;
	Zip: string;
	City: string;
	CountryISO2: string;
	Country: string;
	Email: string;
	State: string;
	Phone: string;
}

export interface CheckOutState {
	billingAddress: CustomerAddress;
	shippingAddress: CustomerAddress;
	deliveryService: {
		ID: number;
		name: string;
		price: number;
	};
}

export const defaultCustomerAddress = {
	City: '',
	Company: '',
	Country: '',
	CountryISO2: '',
	Email: '',
	FirstName: '',
	HouseNumber: '',
	LastName: '',
	Phone: '',
	State: '',
	Street: '',
	Zip: '',
};

const checkOut_initial_state: CheckOutState = {
	billingAddress: defaultCustomerAddress,
	shippingAddress: defaultCustomerAddress,
	deliveryService: {
		ID: 0,
		name: '',
		price: 0,
	},
};

export const checkOutSlice = createSlice({
	name: 'checkOutInfo',
	initialState: checkOut_initial_state,
	reducers: {
		set_checkout_info: (state, action: PayloadAction<Partial<CheckOutState>>) => {
			Object.assign(state, action.payload);
		},
		set_billing_address_info: (state, action: PayloadAction<Partial<CustomerAddress>>) => {
			Object.assign(state.billingAddress, action.payload);
		},
		set_shipping_address_info: (state, action: PayloadAction<Partial<CustomerAddress>>) => {
			Object.assign(state.shippingAddress, action.payload);
		},
	},
});

// Action creators are generated for each case reducer function
export const checkOutActions = checkOutSlice.actions;
