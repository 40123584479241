import { BodySingleBlock } from '../layouts/BodySingleBlock';
import { Box, BoxProps, Checkbox, FormControlLabel } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store/core.store';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { ShoeCollectionOne } from '../shared/ProductList';
import { externalPublicMediaUrl } from '../../environments/environments';
import { BillingAddressForm } from './form/billingAddressForm';
import { ShippingAddressForm } from './form/shippingAddressForm';
import { DeliveryServiceForm } from './form/deliveryServiceForm';
import { GetCountryList } from './form/GetCountryList';
import { PrimaryBlackButton } from '../UI_Components/UI_Buttons';
import { useNavigate } from 'react-router-dom';
import { api_post_data } from '../../environments/api_handler';
import { appToolsActions } from '../../store/reducers/appTools.reducer';
import { EditCartItemQuantity } from './CartRightSidebar';
import { checkOutActions } from '../../store/reducers/checkout_info.reducer';

export const UseBillingAddressForShipping = () => {
	const dispatcher = useDispatch();
	const billingAddress = useSelector((e: AppState) => e.checkout_info.billingAddress);

	const [checked, setChecked] = useState(false);
	useEffect(() => {
		if (checked) {
			dispatcher(checkOutActions.set_shipping_address_info({ ...billingAddress }));
		}
	}, [checked]);
	return (
		<Box className='flex gap-4 items-center'>
			<FormControlLabel
				control={<Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)} />}
				label='Apply billing address to shipping address'
			/>
		</Box>
	);
};

export const SingleTitle = (props: BoxProps) => {
	return <Box className='font-black text-4xl capitalize' {...props} />;
};

export const ConfirmOrderBlock = (props: BoxProps) => {
	return (
		<Box className='p-4 border-2 border-black rounded-2xl'>
			<Box {...props} className={'flex flex-col gap-8 ' + (props.className ? props.className : '')} />
		</Box>
	);
};

export const ConfirmOrderBlockTitle = (props: BoxProps) => {
	return <Box {...props} className='text-2xl font-bold' />;
};

const CheckOutSummaryBlock = () => {
	const cartItems = useSelector((e: AppState) => e.product_cart.items);
	const deliveryService = useSelector((e: AppState) => e.checkout_info.deliveryService);

	const summaryPrice = useMemo(() => {
		return _.sum(cartItems.map((item) => item.price * item.quantity));
	}, [cartItems]);

	const SummaryTextItems = [
		{
			left: <span>Subtotal</span>,
			right: <span>{(summaryPrice * (100 / 119)).toFixed(2)}</span>,
		},
		{
			left: <span>VAT</span>,
			right: <span>19%</span>,
		},
		{
			left: <span>Total</span>,
			right: <span>{summaryPrice.toFixed(2)}</span>,
		},
		{
			left: <span>Shipping</span>,
			right: <span>{deliveryService.price.toFixed(2)}</span>,
		},
		{
			left: <span className='font-black uppercase'>Order Total</span>,
			right: <span className='font-black uppercase'>€ {(summaryPrice + deliveryService.price).toFixed(2)}</span>,
		},
	];
	return (
		<>
			<Box className='flex flex-col text-xl'>
				{SummaryTextItems.map((item, i) => (
					<Box key={i} className='flex justify-between gap-4'>
						<Box className='max-w-[200px]'>{item.left}</Box>
						<Box className='max-w-[320px]'>{item.right}</Box>
					</Box>
				))}
			</Box>
		</>
	);
};

const ProceedToPaymentButton = () => {
	const cartItems = useSelector((e: AppState) => e.product_cart.items);
	const checkout_info = useSelector((e: AppState) => e.checkout_info);
	const navigate = useNavigate();
	const dispatcher = useDispatch();
	return (
		<PrimaryBlackButton
			onClick={async () => {
				await api_post_data('test-api-input/billbee/paypal/createOrder', {
					orders: cartItems,
					billingAddress: checkout_info.billingAddress,
					deliveryAddress: checkout_info.shippingAddress,
					deliveryService: checkout_info.deliveryService,
				}).catch(() => {
					dispatcher(
						appToolsActions.set_apptools_info({
							inputFormIsTouched: true,
							snackbar_content: 'Please fill out all the required fields.',
						}),
					);
					throw new Error();
				});
				navigate('/check-out/payment');
			}}
		>
			<Box className='flex gap-4 items-center py-2'>
				<i className='fa-regular fa-credit-card mr-2 text-2xl' /> Proceed to payment
			</Box>
		</PrimaryBlackButton>
	);
};

export const CheckOutPage = () => {
	const cartItems = useSelector((e: AppState) => e.product_cart.items);
	const navigate = useNavigate();
	const dispatcher = useDispatch();
	useEffect(() => {
		dispatcher(
			appToolsActions.set_apptools_info({
				inputFormIsTouched: false,
			}),
		);
	});
	const userInfo = useSelector((e: AppState) => e.user_info.data);

	return (
		<Box className='py-[100px]'>
			<GetCountryList />
			{cartItems.length > 0 ? (
				<>
					<BodySingleBlock className='flex flex-col gap-16 mb-[50px]'>
						<SingleTitle>Your items</SingleTitle>
						<Box className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4'>
							{cartItems.map((item, i) => {
								const prodInfo = ShoeCollectionOne.find((shoe) => shoe.SKU === item.SKU);
								if (!prodInfo) return <></>;
								return (
									<Fragment key={i}>
										<Box className='flex justify-between gap-4 p-4 rounded-2xl border-2 border-slate-500/40'>
											<Box>
												<img
													src={`${externalPublicMediaUrl}${prodInfo.avatar}`}
													className='w-[100px] aspect-1 object-center object-contain'
												/>
											</Box>
											<Box className='flex-grow'>
												<Box className='flex justify-end'>
													<Box className='max-w-[220px] flex flex-col text-end'>
														<Box className='font-bold'>{prodInfo.name}</Box>
														<Box>Quantity: {item.quantity}</Box>
														<Box>Price: € {item.price.toFixed(2)}</Box>
														<Box className='flex justify-end mt-4'>
															<EditCartItemQuantity product={item} />
														</Box>
													</Box>
												</Box>
											</Box>
										</Box>
									</Fragment>
								);
							})}
						</Box>
					</BodySingleBlock>
					<BodySingleBlock className='mb-[50px]'>
						<Box className='grid grid-cols-1 lg:grid-cols-2 gap-8'>
							<ConfirmOrderBlock>
								<ConfirmOrderBlockTitle>
									<i className='fa-regular fa-address-card mr-4' />
									Billing address
								</ConfirmOrderBlockTitle>
								<BillingAddressForm />
								{userInfo.ID ? (
									<>
										<Box>
											<span className='cursor-pointer underline' onClick={() => navigate('/user-profile')}>
												<i className='fa-regular fa-pen-to-square mr-2' /> Change billing address in your profile
											</span>
										</Box>
									</>
								) : (
									<></>
								)}
								<Box className='flex justify-end'>
									<UseBillingAddressForShipping />
								</Box>
							</ConfirmOrderBlock>
							<ConfirmOrderBlock>
								<ConfirmOrderBlockTitle>
									<i className='fa-regular fa-truck-fast mr-4' />
									Shipping address
								</ConfirmOrderBlockTitle>
								<ShippingAddressForm />
								{userInfo.ID ? (
									<>
										<Box>
											<span className='cursor-pointer underline' onClick={() => navigate('/user-profile')}>
												<i className='fa-regular fa-pen-to-square mr-2' /> Change shipping address in your profile
											</span>
										</Box>
									</>
								) : (
									<></>
								)}
							</ConfirmOrderBlock>
							<ConfirmOrderBlock>
								<ConfirmOrderBlockTitle>Shipping Service</ConfirmOrderBlockTitle>
								<DeliveryServiceForm />
							</ConfirmOrderBlock>
							<ConfirmOrderBlock className='justify-between h-full'>
								<Box className='flex flex-col gap-8'>
									<ConfirmOrderBlockTitle>Order Summary</ConfirmOrderBlockTitle>
									<CheckOutSummaryBlock />
								</Box>
								<Box className='flex gap-4 justify-end'>
									<ProceedToPaymentButton />
								</Box>
							</ConfirmOrderBlock>
						</Box>
					</BodySingleBlock>
				</>
			) : (
				<>
					<BodySingleBlock>
						<Box className='flex gap-4 flex-col items-center'>
							<span className='font-semibold'>Your cart is empty</span>
							<PrimaryBlackButton onClick={() => navigate('/')}>
								<i className='fa-regular fa-house-heart mr-2' /> Back to home
							</PrimaryBlackButton>
						</Box>
					</BodySingleBlock>
				</>
			)}
		</Box>
	);
};
