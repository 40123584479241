import { Box, BoxProps } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import styled from '@emotion/styled';

const SingleTitle = (props: BoxProps) => {
	return <Box {...props} className='text-2xl font-black uppercase mb-8' />;
};

const SingleItem = (props: BoxProps) => {
	const MyBox = styled(Box)`
		* {
			text-decoration: none;
			color: white;
			font-weight: bold;
		}
	`;
	return <MyBox {...props} className='cursor-pointer font-semibold' />;
};

export const MainFooter = () => {
	const navigate = useNavigate();
	return (
		<>
			<Box className='min-h-[60vh] bg-black flex flex-col md:flex-row justify-around py-[50px] text-white px-8 mt-[100px]'>
				<Box className='flex flex-col'>
					<SingleTitle>Company</SingleTitle>
					<Box className='flex flex-col'>
						<SingleItem onClick={() => navigate('/')}>Home</SingleItem>
						<SingleItem>
							<HashLink smooth to='/#homeAboutUs'>
								About Us
							</HashLink>
						</SingleItem>
					</Box>
				</Box>
				<Box>
					<SingleTitle>Address</SingleTitle>
					<Box className='flex flex-col'>
						<SingleItem>Muster Straße 14,</SingleItem>
						<SingleItem>94321 Musterburg,</SingleItem>
						<SingleItem>Germany</SingleItem>
						<SingleItem>Tax Id.Nr: 647291927463</SingleItem>
					</Box>
				</Box>
			</Box>
		</>
	);
};
