import axios from 'axios';
import _ from 'lodash';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { allCountriesActions, SingleCountry } from '../../../store/reducers/allCountries.reducer';

export const GetCountryList = () => {
	const dispatcher = useDispatch();

	async function get_countries() {
		const europe = await axios.get('https://restcountries.com/v3.1/region/europe?fields=name,cca2,flag');
		const engCountries = await axios.get('https://restcountries.com/v3.1/lang/eng?fields=name,cca2,flag');
		const vn = await axios.get('https://restcountries.com/v3.1/lang/vie?fields=name,cca2,flag');
		if (!europe.data || !engCountries.data || !vn.data) return;
		const rawResult = _.concat(europe.data, engCountries.data, vn.data);
		const result = _.uniqBy(rawResult, function (e: SingleCountry) {
			return e.cca2;
		});
		return dispatcher(allCountriesActions.set_all_countries_info({ countries: result }));
	}

	useEffect(() => {
		get_countries().catch();
	}, []);
	return <></>;
};
