import { useEffect } from 'react';
import { all_screen_size, screenSizeActions } from '../../store/reducers/screensize.reducer';
import { useDispatch } from 'react-redux';

export const Window_Resize_Detector = () => {
	const dispatcher = useDispatch();

	const getScreenSize = (size: number): all_screen_size => {
		if (size < 640) {
			return all_screen_size.xs;
		} else if (size < 768) {
			return all_screen_size.sm;
		} else if (size < 1024) {
			return all_screen_size.md;
		} else if (size < 1280) {
			return all_screen_size.lg;
		} else if (size < 1536) {
			return all_screen_size.xl;
		} else {
			return all_screen_size.xxl;
		}
	};

	useEffect(() => {
		window.addEventListener('resize', () => {
			dispatcher(screenSizeActions.setScreenSize(getScreenSize(window.innerWidth)));
		});
		dispatcher(screenSizeActions.setScreenSize(getScreenSize(window.innerWidth)));
	}, []);

	return <></>;
};
