import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client';
import { apiUrl } from '../environments/environments';
import { onError } from '@apollo/client/link/error';
import { reduxStore } from '../store/core.store';
import { appToolsActions } from '../store/reducers/appTools.reducer';

const errorLink = onError((error) => {
	logGraphqlError(error);
});

const httpLink = new HttpLink({
	uri: `${apiUrl}/graphql/`,
	// Enable sending cookies over cross-origin requests
	credentials: 'include',
});

export const graphQLclient = new ApolloClient({
	cache: new InMemoryCache({
		addTypename: false,
	}),

	link: from([errorLink, httpLink]),
});

export const logGraphqlError = (error: any) => {
	const graphQLNetworkError: any = error.networkError;
	const networkErrorMsgs = graphQLNetworkError?.result?.errors;
	if (networkErrorMsgs && Array.isArray(networkErrorMsgs) && networkErrorMsgs.length > 0) {
		reduxStore.dispatch(
			appToolsActions.set_apptools_info({
				app_has_error: true,
				app_error_message: networkErrorMsgs.map((item) =>
					item.message ? `[GraphQL Network Error] ${item.message}` : '',
				),
			}),
		);
		for (const item of networkErrorMsgs) {
			console.log(item.message);
		}
		return;
	}
	const graphQLbasicError = error.graphQLErrors;
	const graphQLErrorsWhiteList = ['UNAUTHENTICATED', 'FORBIDDEN'];
	if (graphQLbasicError && graphQLbasicError.length > 0) {
		const graphQLbasicErrorMsgArr = graphQLbasicError.map((item: any) =>
			item.message ? `[GraphQL Error] ${item.message}` : '',
		);
		const errIsInWhitelist = graphQLbasicError.find((item: any) => graphQLErrorsWhiteList.includes(item.code));
		if (!errIsInWhitelist) {
			reduxStore.dispatch(
				appToolsActions.set_apptools_info({
					app_has_error: true,
					app_error_message: graphQLbasicErrorMsgArr,
				}),
			);
		}
		for (const err of graphQLbasicError) {
			console.log(`[graphQLNetworkError] Message: ${err.message}, code: ${err.code}`);
		}
		return;
	}
	console.log(error);
	console.log(JSON.stringify(error));
	return;
};
