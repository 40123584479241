import { Box, BoxProps } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store/core.store';
import { Fragment, useEffect, useMemo } from 'react';
import gsap from 'gsap';
import { ShoeCollectionOne } from '../shared/ProductList';
import { externalPublicMediaUrl } from '../../environments/environments';
import _ from 'lodash';
import { PrimaryBlackButton, PrimaryOutlinedBlackButton } from '../UI_Components/UI_Buttons';
import { appToolsActions } from '../../store/reducers/appTools.reducer';
import { useNavigate } from 'react-router-dom';
import { productCartActions, ProductCartSingleItem } from '../../store/reducers/productCartInfo.reducer';

export const EditCartItemQuantity = (props: { product: ProductCartSingleItem }) => {
	const dispatcher = useDispatch();
	const userInfo = useSelector((e: AppState) => e.user_info.data);
	const cartItems = useSelector((e: AppState) => e.product_cart.items);
	const foundProduct = cartItems.find((cartItem) => cartItem.SKU === props.product.SKU);
	const EditButton = (props: BoxProps) => (
		<Box
			{...props}
			className='w-8 text-sm aspect-1 rounded-full cursor-pointer flex items-center justify-center hover:shadow-sm hover:shadow-black/40'
		/>
	);
	return (
		<>
			{foundProduct ? (
				<>
					<Box className='flex gap-2 items-center'>
						<EditButton
							onClick={() => {
								dispatcher(
									productCartActions.add_product_to_cart({ amountToAdd: -1, userID: userInfo.ID, ...props.product }),
								);
							}}
						>
							<i className='fa-regular fa-minus' />
						</EditButton>
						<span className='font-semibold text-sm'>{foundProduct.quantity}</span>
						<EditButton
							onClick={() => {
								dispatcher(
									productCartActions.add_product_to_cart({ amountToAdd: 1, userID: userInfo.ID, ...props.product }),
								);
							}}
						>
							<i className='fa-regular fa-plus' />
						</EditButton>
					</Box>
				</>
			) : (
				<></>
			)}
		</>
	);
};

export const CartRightSidebar = () => {
	const dispatcher = useDispatch();
	const navigate = useNavigate();
	const isOpen = useSelector((e: AppState) => e.appTools.rightSideBarIsOpen);
	useEffect(() => {
		if (isOpen) {
			gsap.to('#cartRightSidebar', {
				x: 0,
			});
		} else {
			gsap.to('#cartRightSidebar', {
				x: 400,
			});
		}
	}, [isOpen]);

	const cartItems = useSelector((e: AppState) => e.product_cart.items);

	const summaryPrice = useMemo(() => {
		return _.sum(cartItems.map((item) => item.price * item.quantity));
	}, [cartItems]);

	const SummaryTextItems = [
		{
			left: <span>Subtotal</span>,
			right: <span>{(summaryPrice * (100 / 119)).toFixed(2)}</span>,
		},
		{
			left: <span>VAT</span>,
			right: <span>19%</span>,
		},
		{
			left: <span className='font-black uppercase'>Total</span>,
			right: <span>€ {summaryPrice.toFixed(2)}</span>,
		},
	];

	return (
		<>
			<Box
				className='fixed w-screen h-screen top-0 left-0 z-30 bg-black/40'
				onClick={() => dispatcher(appToolsActions.set_apptools_info({ rightSideBarIsOpen: false }))}
				hidden={!isOpen}
			/>
			<Box
				id='cartRightSidebar'
				className='fixed right-0 top-0 h-[100vh] w-[350px] max-w-[90vw] bg-white translate-x-[400px] z-40 py-[100px] px-4'
			>
				<Box className='flex flex-col gap-16 h-full overflow-y-scroll'>
					<Box className='text-center text-2xl font-black uppercase'>
						<i className='fa-regular fa-cart-shopping mr-4' />
						<span>Your cart</span>
					</Box>
					<Box className='border-t-2 border-black w-full' />
					<Box className='flex flex-col gap-4'>
						{cartItems.map((item, i) => {
							const prodInfo = ShoeCollectionOne.find((shoe) => shoe.SKU === item.SKU);
							if (!prodInfo) return <Fragment key={i} />;
							return (
								<Fragment key={i}>
									<Box className='flex justify-between items-center gap-4 p-2 rounded-2xl border-2 border-slate-500/40'>
										<Box>
											<img
												src={`${externalPublicMediaUrl}${prodInfo.avatar}`}
												className='w-[100px] aspect-1 object-center object-contain'
											/>
										</Box>
										<Box className='flex-grow'>
											<Box className='max-w-[220px] flex flex-col text-end'>
												<Box className='font-bold'>{prodInfo.name}</Box>
												<Box>Quantity: {item.quantity}</Box>
												<Box>Price: € {item.price.toFixed(2)}</Box>
												<Box className='flex justify-end mt-4'>
													<EditCartItemQuantity product={item} />
												</Box>
											</Box>
										</Box>
									</Box>
								</Fragment>
							);
						})}
					</Box>
					<Box className='border-t-2 border-black w-full' />
					<Box className='flex flex-col gap-8'>
						<Box className='flex flex-col'>
							{SummaryTextItems.map((item, i) => (
								<Box key={i} className='flex justify-between gap-4'>
									<Box className='max-w-[100px]'>{item.left}</Box>
									<Box className='max-w-[220px]'>{item.right}</Box>
								</Box>
							))}
						</Box>
						<Box className='flex justify-end gap-4'>
							<PrimaryOutlinedBlackButton
								onClick={() => {
									dispatcher(
										appToolsActions.set_apptools_info({
											rightSideBarIsOpen: false,
										}),
									);
								}}
							>
								<i className='fa-regular fa-xmark mr-2' /> Close
							</PrimaryOutlinedBlackButton>
							<PrimaryBlackButton
								onClick={() => {
									navigate('/check-out');
									dispatcher(
										appToolsActions.set_apptools_info({
											rightSideBarIsOpen: false,
										}),
									);
								}}
							>
								<i className='fa-regular fa-credit-card mr-2' /> Check out
							</PrimaryBlackButton>
						</Box>
					</Box>
				</Box>
			</Box>
		</>
	);
};
