import { ShopCurrency } from '../../store/reducers/app_core_state.reducer';

export interface SingleShoe {
	ID: number;
	SKU: string;
	name: string;
	price: number;
	currency: ShopCurrency;
	manufacturer: string;
	avatar: string;
}

export const ShoeCollectionOne: SingleShoe[] = [
	{
		ID: 1,
		SKU: '81692830',
		name: 'Puller H83',
		price: 23.45,
		currency: ShopCurrency.EUR,
		manufacturer: 'Nike',
		avatar: '/img/shoe-collection/1/Asset 1.svg',
	},
	{
		ID: 2,
		SKU: '816231830',
		name: 'Mystery A62',
		price: 55.1,
		currency: ShopCurrency.EUR,
		manufacturer: 'Adidas',
		avatar: '/img/shoe-collection/1/Asset 2.svg',
	},
	{
		ID: 3,
		SKU: '43292830',
		name: 'Basin C38',
		price: 38.27,
		currency: ShopCurrency.EUR,
		manufacturer: 'Puma',
		avatar: '/img/shoe-collection/1/Asset 3.svg',
	},
	{
		ID: 4,
		SKU: '81323928340',
		name: 'Grand Rosen X84',
		price: 63.4,
		currency: ShopCurrency.EUR,
		manufacturer: 'Nike',
		avatar: '/img/shoe-collection/1/Asset 4.svg',
	},
	{
		ID: 5,
		SKU: '234214524',
		name: 'Station X',
		price: 32.65,
		currency: ShopCurrency.EUR,
		manufacturer: 'Adidas',
		avatar: '/img/shoe-collection/1/Asset 5.svg',
	},
	{
		ID: 6,
		SKU: '816562430',
		name: 'Nixon J8',
		price: 42.32,
		currency: ShopCurrency.EUR,
		manufacturer: 'Puma',
		avatar: '/img/shoe-collection/1/Asset 6.svg',
	},
	{
		ID: 7,
		SKU: '482989338',
		name: 'Triber N83',
		price: 25.43,
		currency: ShopCurrency.EUR,
		manufacturer: 'Nike',
		avatar: '/img/shoe-collection/1/Asset 7.svg',
	},
	{
		ID: 8,
		SKU: '4812429849',
		name: 'Eager X',
		price: 33.15,
		currency: ShopCurrency.EUR,
		manufacturer: 'Adidas',
		avatar: '/img/shoe-collection/1/Asset 8.svg',
	},
];
