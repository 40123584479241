import axios, { AxiosRequestConfig } from 'axios';
import { apiUrl } from './environments';
import { appToolsActions } from '../store/reducers/appTools.reducer';
import { reduxStore } from '../store/core.store';

export const api_post_data = async (url_suffix: string, data?: any, options?: AxiosRequestConfig) => {
	const basic_options: AxiosRequestConfig = {
		withCredentials: true,
	};

	const myConfig = (): AxiosRequestConfig => {
		if (options) {
			return Object.assign(basic_options, options);
		} else {
			return basic_options;
		}
	};

	return axios.post(`${apiUrl}/${url_suffix}`, data, myConfig());
};

axios.interceptors.response.use(
	(res: any) => {
		if (res.data) {
			if (res.data.message) {
				reduxStore.dispatch(
					appToolsActions.set_apptools_info({
						snackbar_content: res.data.message,
					}),
				);
			}
		}
		return res;
	},
	async (error: any) => {
		if (error.response.data) {
			const msg = error.response.data?.message;
			if (msg) {
				if (Array.isArray(msg) && typeof msg[0] === 'string') {
					reduxStore.dispatch(
						appToolsActions.set_apptools_info({
							snackbar_content: msg[0],
						}),
					);
				} else if (typeof msg === 'string') {
					reduxStore.dispatch(
						appToolsActions.set_apptools_info({
							snackbar_content: msg,
						}),
					);
				} else {
					reduxStore.dispatch(
						appToolsActions.set_apptools_info({
							app_has_error: msg,
							app_error_message: [JSON.stringify(msg)],
						}),
					);
				}
			}
		}
		console.log(error?.response);
		return Promise.reject(error);
	},
);
