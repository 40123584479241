import { configureStore } from '@reduxjs/toolkit';
import { Table_UI_Slice } from './reducers/table_UI.reducer';
import { AppCoreStateSlice } from './reducers/app_core_state.reducer';
import { userInfoSlice } from './reducers/userinfo.reducer';
import { screenSizeSlice } from './reducers/screensize.reducer';
import createSagaMiddleware from 'redux-saga';
import { allUsersReducer } from './saga/all_users';
import { BlogPostSlice } from './reducers/blog_post_info.reducer';
import { BlogPost_CategoryInfo_Slice } from './reducers/category_info.reducer';
import { breadCrumbSlice } from './reducers/breadcrumb.reducer';
import { appToolsSlice } from './reducers/appTools.reducer';
import { App_OverallInfo_Slice } from './reducers/app_overall_info/app_overall_info.reducer';
import { productCartSlice } from './reducers/productCartInfo.reducer';
import { checkOutSlice } from './reducers/checkout_info.reducer';
import { allCountriesSlice } from './reducers/allCountries.reducer';

export const sagaMiddleware = createSagaMiddleware();
export const reduxStore = configureStore({
	reducer: {
		table_UI: Table_UI_Slice.reducer,
		app_core_state: AppCoreStateSlice.reducer,
		app_overall_info: App_OverallInfo_Slice.reducer,
		checkout_info: checkOutSlice.reducer,
		allCountries: allCountriesSlice.reducer,
		product_cart: productCartSlice.reducer,
		screenSize: screenSizeSlice.reducer,
		breadCrumb: breadCrumbSlice.reducer,
		all_users: allUsersReducer,
		user_info: userInfoSlice.reducer,
		blog_post: BlogPostSlice.reducer,
		blogpost_category_info: BlogPost_CategoryInfo_Slice.reducer,
		appTools: appToolsSlice.reducer,
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(sagaMiddleware),
});

const makeStore = () => reduxStore;

export type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<AppStore['getState']>;
