import React, { ChangeEvent, useMemo, useState } from 'react';
import { Box, TextField } from '@mui/material';
import { api_post_data } from '../../environments/api_handler';
import { useDispatch } from 'react-redux';
import { appToolsActions } from '../../store/reducers/appTools.reducer';
import { passwordStrength } from 'check-password-strength';
import { BlackCircularProgress } from '../UI_Components/UI_Progress';
import { PrimaryBlackButton } from '../UI_Components/UI_Buttons';
import { useNavigate } from 'react-router-dom';

export const LoginPage = () => {
	const navigate = useNavigate();
	const dispatcher = useDispatch();

	const [isLogin, setIsLogin] = useState(true);

	const [signInData, setSignInData] = useState({
		email: '',
		password: '',
	});
	const [signUpData, setSignUpData] = useState({
		email: '',
		password: '',
		confirmPassword: '',
	});
	const [registered, setRegistered] = useState(false);
	function handleSignInForm(e: ChangeEvent<any>) {
		if (e.target.name) {
			setSignInData({ ...signInData, [e.target.name]: e.target.value });
		}
	}
	function handleRegisterForm(e: ChangeEvent<any>) {
		if (e.target.name) {
			setSignUpData({ ...signUpData, [e.target.name]: e.target.value });
		}
	}
	function submitSignInForm() {
		api_post_data('auth/userBasicSignIn', signInData)
			.then(() => {
				navigate('/');
				window.location.reload();
			})
			.catch();
	}
	function submitRegistrationForm() {
		if ([0, 1].includes(passwordStrength(signUpData.password).id)) {
			dispatcher(
				appToolsActions.set_apptools_info({
					snackbar_content: 'Password too weak, please choose another password',
				}),
			);
			return;
		}
		if (signUpData.password !== signUpData.confirmPassword) {
			dispatcher(
				appToolsActions.set_apptools_info({
					snackbar_content: 'Password and confirmed password are not the same',
				}),
			);
			return;
		}
		setRegisterIsWaiting(true);
		api_post_data('auth/userBasicRegister', signUpData)
			.then(() => {
				setSignUpData({ confirmPassword: '', email: '', password: '' });
				setRegisterIsWaiting(false);
				setRegistered(true);
			})
			.catch(() => {
				setRegisterIsWaiting(false);
			});
	}

	const registerPasswordColor = useMemo(() => {
		const strength = passwordStrength(signUpData.password);
		if (strength.id === 0) {
			return '#fc3939';
		}
		if (strength.id === 1) {
			return '#ff7057';
		}
		if (strength.id === 2) {
			return '#fff785';
		}
		if (strength.id === 3) {
			return '#78fd76';
		}
	}, [signUpData.password]);

	const [registerIsWaiting, setRegisterIsWaiting] = useState(false);

	return (
		<>
			<Box className='w-full flex justify-center items-center px-8 py-[100px] min-h-[60vh]'>
				<Box className='grid grid-cols-1 gap-8 gap-16 w-full max-w-[500px] p-8 rounded-[30px] bg-gradient-to-br from-blue-200/40 via-indigo-200/40 to-violet-200/40'>
					{isLogin ? (
						<>
							<Box className='text-center text-2xl uppercase font-black'>Sign In</Box>
							<TextField label='Email' name='email' value={signInData.email} onChange={handleSignInForm} />
							<TextField
								label='Password'
								type='password'
								name='password'
								value={signInData.password}
								onChange={handleSignInForm}
							/>
							<Box className='flex flex-wrap justify-around gap-8'>
								<PrimaryBlackButton onClick={submitSignInForm}>
									<i className='fa-regular fa-right-to-bracket mr-2' />
									Sign In
								</PrimaryBlackButton>
								<PrimaryBlackButton
									onClick={() => {
										setIsLogin(false);
									}}
								>
									<i className='fa-regular fa-user-plus mr-2' />
									Sign Up
								</PrimaryBlackButton>
							</Box>
						</>
					) : (
						<>
							{registered ? (
								<>
									<Box className='text-center text-2xl uppercase font-black'>Registered successfully</Box>
									<Box className='font-semibold'>
										Congratulations! You've registered successfully. Please check your email to activate your account.
									</Box>
									<span
										className='underline cursor-pointer'
										onClick={() => {
											setRegistered(false);
											setIsLogin(true);
										}}
									>
										<i className='fa-regular fa-left mr-2' />
										Back to sign in
									</span>
								</>
							) : (
								<>
									<Box className='text-center text-2xl uppercase font-black'>Sign Up</Box>
									<TextField label='Email' name='email' value={signUpData.email} onChange={handleRegisterForm} />
									<TextField
										label='Password'
										type='password'
										name='password'
										value={signUpData.password}
										onChange={handleRegisterForm}
									/>
									<TextField
										label='Confirm Password'
										name='confirmPassword'
										type='password'
										value={signUpData.confirmPassword}
										onChange={handleRegisterForm}
									/>
									<span className='text-sm'>
										Password tips: More than 8 charaters, include capitalized letters and special characters for example
										!@#$%^*(&*
									</span>
									<Box className='flex flex-wrap gap-4 items-center'>
										<span>Password strength:</span>
										<Box
											className='w-6 aspect-1 rounded-full'
											style={{
												background: registerPasswordColor,
												boxShadow: `1px 1px 2px 2px ${registerPasswordColor}`,
											}}
										/>
									</Box>
									<Box className='flex flex-wrap justify-center gap-8'>
										{registerIsWaiting ? (
											<>
												<BlackCircularProgress />
											</>
										) : (
											<>
												<PrimaryBlackButton onClick={() => submitRegistrationForm()}>
													<i className='fa-regular fa-check mr-2' />
													Confirm
												</PrimaryBlackButton>
											</>
										)}
									</Box>
									<span
										className='underline cursor-pointer'
										onClick={() => {
											setIsLogin(true);
										}}
									>
										<i className='fa-regular fa-left mr-2' />
										Back to sign in
									</span>
								</>
							)}
						</>
					)}
				</Box>
			</Box>
		</>
	);
};
