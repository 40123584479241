import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store/core.store';
import React, { Fragment } from 'react';
import { Box } from '@mui/material';
import { externalPublicMediaUrl } from '../../../environments/environments';
import { checkOutActions } from '../../../store/reducers/checkout_info.reducer';

export const deliveryServices = [
	{
		ID: 1,
		name: 'DHL',
		price: 0,
		avatar: 'dhl-3bhk2_result.webp',
	},
	{
		ID: 2,
		name: 'FedEx',
		price: 1.99,
		avatar: 'fed-ex-3bjk2_result.webp',
	},
	{
		ID: 3,
		name: 'Hermes',
		price: 2.99,
		avatar: 'hermes-logo-2-n3jk2_result.webp',
	},
];

export const DeliveryServiceForm = () => {
	const dispatcher = useDispatch();
	const serviceInfo = useSelector((e: AppState) => e.checkout_info.deliveryService);

	return (
		<>
			<Box className='grid grid-cols-1 sm:grid-cols-2 gap-8'>
				{deliveryServices.map((service, i) => (
					<Fragment key={i}>
						<Box
							className='border-4 duration-500 border-slate-500/40 rounded-[15px] p-4 flex gap-4 justify-between cursor-pointer'
							style={{
								border: serviceInfo.ID !== service.ID ? 'none' : '',
							}}
							onClick={() => {
								dispatcher(
									checkOutActions.set_checkout_info({
										deliveryService: {
											ID: service.ID,
											name: service.name,
											price: service.price,
										},
									}),
								);
							}}
						>
							<img
								src={`${externalPublicMediaUrl}/img/lieferService/${service.avatar}`}
								className='w-[100px] rounded-2xl aspect-1 object-contain object-center'
							/>
							<Box className='flex flex-col text-end'>
								<span className='font-bold'>{service.name}</span>
								<span>{service.price ? <>€ {service.price.toFixed(2)}</> : <>Free</>}</span>
							</Box>
						</Box>
					</Fragment>
				))}
			</Box>
		</>
	);
};
