import { v4 } from 'uuid';

export interface MainNavMenuContent {
	ID: string;
	title: string;
	link: string;
	order: string;
}

export const mainNavMenuContentItem: MainNavMenuContent = {
	ID: v4(),
	title: 'Menu',
	link: 'https://www.google.com',
	order: '1',
};

export const mainNavMenuContentDefaultVal: MainNavMenuContent[] = [
	{ ...mainNavMenuContentItem, ID: v4() },
	{ ...mainNavMenuContentItem, ID: v4() },
	{ ...mainNavMenuContentItem, ID: v4() },
	{ ...mainNavMenuContentItem, ID: v4() },
];
