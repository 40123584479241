import { Box, BoxProps } from '@mui/material';
import React from 'react';

export const BodySingleBlock = React.forwardRef(function (props: BoxProps, ref: React.ForwardedRef<HTMLElement>) {
	return (
		<Box
			{...props}
			ref={ref}
			className={'w-full max-w-screen-xl px-4'.concat(' ', props.className ? props.className : '')}
		>
			{props.children}
		</Box>
	);
});
