import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const Table_UI_Slice = createSlice({
	name: 'table_UI',
	initialState: {
		search_term: '',
		search_key: '',
	},
	reducers: {
		set_table_search_term: (state, action: PayloadAction<string>) => {
			state.search_term = action.payload;
		},
		set_table_search_key: (state, action: PayloadAction<string>) => {
			state.search_key = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { set_table_search_term, set_table_search_key } = Table_UI_Slice.actions;
