import { ProductCartSingleItem } from '../../store/reducers/productCartInfo.reducer';
import { CustomerAddress } from '../../store/reducers/checkout_info.reducer';

export interface UserInfo {
	ID: number;
	email: string;
	role: UserRoles;
	cart: {
		items: ProductCartSingleItem[];
	}[];
	billing_address: {
		content: CustomerAddress;
	}[];
	shipping_address: {
		content: CustomerAddress;
	}[];
}

export enum UserRoles {
	guest = 'guest',
	subscriber = 'subscriber',
	admin = 'admin',
}
